import React, { useEffect, useState } from 'react';
import { useIntl } from 'context/IntlContext';
import { Text, Box, Container, Divider, Heading } from 'components';
import Seo from '../../components/SEO';
import { keyForTransaltionsPrivacyPoliy } from 'utils/get-translations';

interface Policies {
  title: string;
  subTitle?: string;
  content: string;
}

export const PrivacyPolicy = () => {
  const { t, locale } = useIntl();
  const [title, setTitle] = useState('privacyPolocyPage.spanish.title');
  const [policies, setPolicies] = useState<Policies[]>([
    {
      title: 'privacyPolocyPage.spanish.introductionTitle',
      content: 'privacyPolocyPage.spanish.introductionContent',
    },
    {
      title: 'privacyPolocyPage.spanish.useOurServicesTitle',
      subTitle: 'privacyPolocyPage.spanish.useOurServicesSubTitle',
      content: 'privacyPolocyPage.spanish.useOurServicesContent',
    },
    {
      title: 'privacyPolocyPage.spanish.personalInformationTitle',
      subTitle: 'privacyPolocyPage.spanish.personalInformationSubTitle',
      content: 'privacyPolocyPage.spanish.personalInformationContent',
    },
    {
      title: 'privacyPolocyPage.spanish.consentTitle',
      content: 'privacyPolocyPage.spanish.consentContent',
    },
    {
      title: 'privacyPolocyPage.spanish.removalPersonalInformationTitle',
      content: 'privacyPolocyPage.spanish.removalPersonalInformationContent',
    },
    {
      title: 'privacyPolocyPage.spanish.dataDisclosureTitle',
      content: 'privacyPolocyPage.spanish.dataDisclosureContent',
    },
    {
      title: 'privacyPolocyPage.spanish.keepingPersonalInformationSecureTitle',
      content:
        'privacyPolocyPage.spanish.keepingPersonalInformationSecureContent',
    },
    {
      title: 'privacyPolocyPage.spanish.rigthsTitle',
      content: 'privacyPolocyPage.spanish.rigthsContent',
    },
    {
      title: 'privacyPolocyPage.spanish.internationalTransfersInformationTitle',
      content:
        'privacyPolocyPage.spanish.internationalTransfersInformationContent',
    },
    {
      title: 'privacyPolocyPage.spanish.thirdPartyLinksTitle',
      content: 'privacyPolocyPage.spanish.thirdPartyLinksContent',
    },
    {
      title: 'privacyPolocyPage.spanish.policyModificationsTitle',
      content: 'privacyPolocyPage.spanish.policyModificationsContent',
    },
    {
      title: 'privacyPolocyPage.spanish.policyModificationsTitle',
      content: 'privacyPolocyPage.spanish.policyModificationsContent',
    },
  ]);

  useEffect(() => {
    if (locale === 'es') {
      const keys = keyForTransaltionsPrivacyPoliy('spanish');
      setTitle(keys.title);
      setPolicies(keys.keysTranslate);
    }
    if (locale === 'en') {
      const keys = keyForTransaltionsPrivacyPoliy('english');
      setTitle(keys.title);
      setPolicies(keys.keysTranslate);
    }
    if (locale === 'it') {
      const keys = keyForTransaltionsPrivacyPoliy('italian');
      setTitle(keys.title);
      setPolicies(keys.keysTranslate);
    }
    if (locale === 'pt') {
      const keys = keyForTransaltionsPrivacyPoliy('portuguese');
      setTitle(keys.title);
      setPolicies(keys.keysTranslate);
    }
  }, [locale]);

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Box>
        <Container>
          <Heading
            as="h2"
            sx={{
              color: 'quaternary',
              mb: 7,
              fontWeight: 700,
            }}
          >
            {t(title)}
          </Heading>
          <Divider />
          {policies.map((policy, index) => (
            <div key={index}>
              <Text
                as="p"
                sx={{
                  variant: 'text.underHero',
                  color: 'quaternary',
                  mb: 7,
                  fontWeight: '700',
                }}
              >
                {t(policy.title)}
              </Text>
              {policy.subTitle ? (
                <Text
                  as="p"
                  sx={{
                    fontWeight: 'bold',
                    color: '#5e657b',
                    mb: 7,
                  }}
                >
                  {t(policy.subTitle)}
                </Text>
              ) : null}
              <Text
                as="p"
                variant="underHero"
                dangerouslySetInnerHTML={{
                  __html: t(policy.content, {
                    uu: 'ul',
                    sg: 'strong',
                  }),
                }}
                style={{
                  whiteSpace: 'pre-line',
                }}
                sx={{
                  color: 'quaternary',
                  opacity: '0.8',
                  mb: 10,
                }}
              />
            </div>
          ))}
        </Container>
      </Box>
    </>
  );
};
