export const keyForTransaltionsPrivacyPoliy = (language: string) => {
  return {
    title: `privacyPolocyPage.${language}.title`,
    keysTranslate: [
      {
        title: `privacyPolocyPage.${language}.introductionTitle`,
        content: `privacyPolocyPage.${language}.introductionContent`,
      },
      {
        title: `privacyPolocyPage.${language}.useOurServicesTitle`,
        content: `privacyPolocyPage.${language}.useOurServicesContent`,
      },
      {
        title: `privacyPolocyPage.${language}.personalInformationTitle`,
        content: `privacyPolocyPage.${language}.personalInformationContent`,
      },
      {
        title: `privacyPolocyPage.${language}.consentTitle`,
        content: `privacyPolocyPage.${language}.consentContent`,
      },
      {
        title: `privacyPolocyPage.${language}.removalPersonalInformationTitle`,
        content: `privacyPolocyPage.${language}.removalPersonalInformationContent`,
      },
      {
        title: `privacyPolocyPage.${language}.dataDisclosureTitle`,
        content: `privacyPolocyPage.${language}.dataDisclosureContent`,
      },
      {
        title: `privacyPolocyPage.${language}.keepingPersonalInformationSecureTitle`,
        content: `privacyPolocyPage.${language}.keepingPersonalInformationSecureContent`,
      },
      {
        title: `privacyPolocyPage.${language}.rigthsTitle`,
        content: `privacyPolocyPage.${language}.rigthsContent`,
      },
      {
        title: `privacyPolocyPage.${language}.internationalTransfersInformationTitle`,

        content: `privacyPolocyPage.${language}.internationalTransfersInformationContent`,
      },
      {
        title: `privacyPolocyPage.${language}.thirdPartyLinksTitle`,
        content: `privacyPolocyPage.${language}.thirdPartyLinksContent`,
      },
      {
        title: `privacyPolocyPage.${language}.policyModificationsTitle`,
        content: `privacyPolocyPage.${language}.policyModificationsContent`,
      },
      {
        title: `privacyPolocyPage.${language}.cookiesPolicyTitle`,
        content: `privacyPolocyPage.${language}.cookiesPolicyContent`,
      },
    ],
  };
};

export const keyForTranslationsTermsAndConditions = (language: string) => {
  return {
    title: `termsAndConditionsPage.${language}.title`,
    keys: [
      {
        title: `termsAndConditionsPage.${language}.ourServiceOurCommitmentTitle`,
        content: `termsAndConditionsPage.${language}.ourServiceOurCommitmentContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.termsUseAndServiceTitle`,
        content: `termsAndConditionsPage.${language}.termsUseAndServiceContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.purposeServiceTitle`,
        content: `termsAndConditionsPage.${language}.purposeServiceContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.accessServiceTitle`,
        content: `termsAndConditionsPage.${language}.accessServiceContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.accessPersonalAccountsAuthorisationTitle`,
        content: `termsAndConditionsPage.${language}.accessPersonalAccountsAuthorisationContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.collaborativeProviderPackagesServicesnTitle`,
        content: `termsAndConditionsPage.${language}.collaborativeProviderPackagesServicesnContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.websiteDataInformationChargesSecurityTitle`,
        content: `termsAndConditionsPage.${language}.websiteDataInformationChargesSecurityContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.ourRightChangeTermsTitle`,
        content: `termsAndConditionsPage.${language}.ourRightChangeTermsContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.warrantiesTitle`,
        content: `termsAndConditionsPage.${language}.warrantiesContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.ourLiabilityTitle`,
        content: `termsAndConditionsPage.${language}.ourLiabilityContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.indemnificationTitle`,
        content: `termsAndConditionsPage.${language}.indemnificationContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.terminationSuspensionTitle`,
        content: `termsAndConditionsPage.${language}.terminationSuspensionContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.linkingOurSiteTitle`,
        content: `termsAndConditionsPage.${language}.linkingOurSiteContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.generalApplicableLawTitle`,
        content: `termsAndConditionsPage.${language}.generalApplicableLawContent`,
      },
      {
        title: `termsAndConditionsPage.${language}.questionsComplaintsTitle`,
        content: `termsAndConditionsPage.${language}.questionsComplaintsContent`,
      },
    ],
  };
};
